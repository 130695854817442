<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header
                :title="$t('voucher.vouchers')"
                icon="mdi-credit-card-multiple-outline"
            />
            <v-expansion-panels class="pa-5" v-model="panel" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-icon color="primary darken-1">
                            mdi-clipboard-text-search-outline
                        </v-icon>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <template>
                                            <div>
                                                <v-menu
                                                    ref="menu"
                                                    v-model="menu"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            hide-details
                                                            outlined
                                                            dense
                                                            :rules="rules"
                                                            v-model="fromDate"
                                                            :label="
                                                                $t(
                                                                    'reports.fromDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="fromDate"
                                                        :active-picker.sync="
                                                            activePicker
                                                        "
                                                        @change="saveDate"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <template>
                                            <div>
                                                <v-menu
                                                    ref="menu2"
                                                    v-model="menu2"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            hide-details
                                                            outlined
                                                            dense
                                                            :rules="rules"
                                                            v-model="toDate"
                                                            :label="
                                                                $t(
                                                                    'reports.toDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="toDate"
                                                        :active-picker.sync="
                                                            activePicker2
                                                        "
                                                        @change="saveDate2"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-autocomplete
                                            v-model="selectedType"
                                            :items="voucherTypes"
                                            hide-details
                                            item-text="key"
                                            item-value="value"
                                            :label="$t('voucher.voucherType')"
                                            outlined
                                            dense
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-btn
                                color="primary darken-1 white--text"
                                @click="getData"
                                :disabled="!valid"
                                :min-width="100"
                            >
                                <v-icon
                                    >mdi-clipboard-text-search-outline</v-icon
                                >
                                {{ $t("preview") }}
                            </v-btn>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-data-table
                class="pa-5"
                :headers="headers"
                :items="vouchers"
                :search="search"
                :items-per-page="15"
                :loading="loading"
                :loading-text="$t('loading')"
            >
                <template v-slot:[`item.debit`]="{ item }">
                    {{ item.debit | currency("", item.floatingPoints) }}
                </template>
                <template v-slot:[`item.credit`]="{ item }">
                    {{ item.credit | currency("", item.floatingPoints) }}
                </template>
                <template v-slot:[`item.source`]="{ item }">
                    {{ item.isPaidBill ? $t("voucher.paidBill") : item.notes }}
                </template>
                <template v-slot:top>
                    <v-toolbar flat>
                        <!--Search bar-->
                        <v-text-field
                            v-model="search"
                            dense
                            outlined
                            append-icon="mdi-magnify"
                            :label="$t('search')"
                            single-line
                            hide-details
                        ></v-text-field>
                        <v-spacer></v-spacer>
                        <!--Delete popup-->
                        <confirm-dialog
                            :openDialog="dialogDelete"
                            :onClicked="deleteItemConfirm"
                            :onClose="closeDelete"
                            toolBarColor="red darken-2"
                        ></confirm-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.voucherType`]="{ item }">
                    {{
                        item.voucherType == 2
                            ? $t("voucher.receipt")
                            : item.voucherType == 3
                            ? $t("voucher.payment")
                            : $t("voucher.entry")
                    }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <div class="text-end">
                        <v-row>
                            <v-col cols="4">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-if="isInRole(30)"
                                            @click="print(item.voucherGuid)"
                                            class="ml-2 mr-2"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            mdi-printer-outline
                                        </v-icon>
                                    </template>
                                    <span> {{ $t("print") }} </span>
                                </v-tooltip></v-col
                            >
                            <v-col cols="4"
                                ><v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-if="
                                                item.isPaidBill == false &&
                                                    isInRole(28)
                                            "
                                            @click="editItem(item)"
                                            class="ml-2 mr-2"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span> {{ $t("edit") }} </span>
                                </v-tooltip></v-col
                            >
                            <v-col cols="4"
                                ><v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-if="
                                                item.isPaidBill == false &&
                                                    isInRole(29)
                                            "
                                            @click="deleteItem(item)"
                                            v-bind="attrs"
                                            v-on="on"
                                            color="red"
                                        >
                                            mdi-delete-outline
                                        </v-icon>
                                    </template>
                                    <span> {{ $t("delete") }}</span>
                                </v-tooltip></v-col
                            >
                        </v-row>
                    </div>
                </template>
                <template v-slot:[`item.voucherDate`]="{ item }">
                    {{ item.voucherDate | moment("yyyy-MM-DD") }}
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";

import moment from "moment";

export default {
    components: { ConfirmDialog, PageHeader },
    data() {
        return {
            date: null,
            date2: null,
            activePicker: null,
            menu: false,
            menu2: false,
            activePicker2: null,
            valid: null,
            fromDate: moment(new Date()).format("yyyy-MM-DD"),
            toDate: moment(new Date()).format("yyyy-MM-DD"),
            selectedType: null,
            panel: [0, 1],
            loading: true,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            voucherTypes: [
                {
                    key: this.$t("voucher.payment"),
                    value: 3
                },
                {
                    key: this.$t("voucher.receipt"),
                    value: 2
                }
            ],
            sourceTypes: [
                {
                    key: this.$t("customer.customer"),
                    value: 1
                },
                {
                    key: this.$t("bill.bill"),
                    value: 2
                }
            ],
            headers: [
                {
                    text: this.$t("voucher.voucherNumber"),
                    value: "voucherNumber"
                },
                {
                    text: this.$t("voucher.voucherDate"),
                    value: "voucherDate"
                },
                {
                    text: this.$t("account"),
                    value: "voucherAccount"
                },
                {
                    text: this.$t("voucher.debit"),
                    value: "debit"
                },
                {
                    text: this.$t("voucher.credit"),
                    value: "credit"
                },
                {
                    text: this.$t("voucher.currency"),
                    value: "currencySymbol"
                },
                {
                    text: this.$t("voucher.voucherType"),
                    value: "voucherType"
                },
                { text: this.$t("voucher.source"), value: "source" },
                { text: "", value: "actions" }
            ],
            vouchers: [],
            editedItem: {
                voucherGuid: null,
                voucherNumber: null,
                voucherType: null,
                voucherDate: null,
                notes: null,
                currencyValue: null,
                currencyGuid: null,
                sourceGuid: null,
                sourceType: null,
                vouchersSettingId: null,
                entries: []
            },
            defaultItem: {
                voucherGuid: null,
                voucherNumber: null,
                voucherType: null,
                voucherDate: null,
                notes: null,
                currencyValue: null,
                currencyGuid: null,
                sourceGuid: null,
                sourceType: null,
                vouchersSettingId: null,
                entries: []
            },
            rules: [value => !!value || value == 0 || this.$t("required")]
        };
    },
    created() {
        this.loading = true;
        this.getData();
    },
    watch: {
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        editItem(item) {
            this.editedIndex = this.vouchers.indexOf(item);
            Object.assign(this.editedItem, item);

            var routeName = "";

            if (this.editedItem.voucherType == 2) routeName = "voucher.receipt";
            else if (this.editedItem.voucherType == 3)
                routeName = "voucher.payment";

            this.$router.push({
                name: routeName,
                params: {
                    id: this.editedItem.voucherGuid,
                    vType: this.editedItem.voucherType
                }
            });
        },
        deleteItem(item) {
            this.editedIndex = this.vouchers.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            var deleteItem = this.vouchers[this.editedIndex];
            axios
                .delete("Vouchers/Delete?id=" + deleteItem.voucherGuid)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                    this.getData();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.getData();
                    console.log(e);
                });

            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        getData() {
            axios
                .get("Vouchers/Get", {
                    params: {
                        fromDate: this.fromDate,
                        toDate: this.toDate,
                        selectedType: this.selectedType
                    }
                })
                .then(response => {
                    this.vouchers = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        print(voucherGuid) {
            axios({
                url: "Vouchers/PrintVoucher",
                method: "GET",
                responseType: "blob",
                params: {
                    voucherGuid: voucherGuid
                }
            }).then(response => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "سند.pdf");
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        },
        saveDate(date) {
            this.$refs.menu.save(date);
        },
        saveDate2(date) {
            this.$refs.menu2.save(date);
        }
    }
};
</script>
